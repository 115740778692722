import { Heading } from '@Infowijs-eng/component-library/components'
import React, {
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {
  useNavigate, useParams,
} from 'react-router-dom'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import {
  getHoyClientForUserPermissions,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import { useSelector } from 'react-redux'
import ScanContainer from '../components/HoyScan/ScanContainer'
import AppLinks from '../components/AppLinks'
import EmailLogin from '../components/EmailLogin'
import getClientTransferToken from '../selectors/authentication/getClientTransferToken'
import getUserPermissions from '../selectors/authentication/getUserPermissions'
import getName from '../selectors/customer/getName'
import getPremiumAppName from '../selectors/customer/getPremiumAppName'
import getPremiumAppSlogan from '../selectors/customer/getPremiumAppSlogan'
import isLoaded from '../selectors/customer/isLoaded'
import LoadingScreen from './LoadingScreen'
import Layout from '../components/Layout'
import requestCustomerMeta from '../actions/customer/requestCustomerMeta'

function SignInScreen() {
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()

  const transferToken = useSelector(getClientTransferToken)
  const customerLoaded = useSelector(isLoaded)
  const customerName = useSelector(getName)
  const customerPremiumAppName = useSelector(getPremiumAppName)
  const customerPremiumAppSlogan = useSelector(getPremiumAppSlogan)
  const userPermissions = useSelector(getUserPermissions)

  useEffect(() => {
    if (transferToken != null) {
      const endpoint = getHoyClientForUserPermissions(
        customerName,
        userPermissions,
      )
      window.location = `${endpoint}/?token=${transferToken}`
    }
  }, [customerName, userPermissions, transferToken, navigate])

  const { customerProduct, productType } = useParams()
  const [loading, setLoading] = useState(false)

  useLayoutEffect(() => {
    if (customerProduct && !customerLoaded && !loading) {
      setLoading(true)
      promisifyDispatch(requestCustomerMeta({
        customerProduct,
        productType,
      })).then(() => {
        setLoading(false)
      }).catch((e) => {
        if (!e || !e.response || e.response.status !== 404) {
          // eslint-disable-next-line no-alert
          alert(t({ id: 'error.default' }))
        }
        navigate('/')
        setLoading(false)
      })
    }
  }, [customerProduct, productType, customerLoaded, loading, setLoading, navigate, requestCustomerMeta])

  if (loading) {
    return (
      <LoadingScreen />
    )
  }

  return (
    <Layout>
      <div
        className={`flex-col self-center xl:self-start lg:max-w-screen-lg w-full mx-auto md:px-6 xl:mt-36
          space-y-10`}
      >
        <div>
          <header className="text-center">
            <Heading
              fontSize={Heading.size.H1}
            >
              <span className="text-theme-500">
                <Trans id="sign-in.welcome" values={{ customerPremiumAppName: customerPremiumAppName || 'Hoy' }}>
                  {'Welkom bij {customerPremiumAppName}!'}
                </Trans>
              </span>
            </Heading>
            <p className="text-gray-300 md:text-lg font-bold leading-relaxed">
              {customerPremiumAppSlogan || (
                <Trans id="sign-in.subtitle">De nieuwe standaard voor schoolcommunicatie</Trans>
              )}
            </p>
          </header>
        </div>
        <div className={customerProduct ? 'hidden md:flex justify-center' : 'flex justify-center'}>
          <ScanContainer />
        </div>
        <div className="flex flex-col md:flex-col-reverse gap-28 md:gap-8">
          {!!customerProduct && <EmailLogin />}
          <AppLinks />
        </div>
      </div>
    </Layout>
  )
}

export default SignInScreen
