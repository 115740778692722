import actionTypes from '../action-types'

const initialState = {
  loaded: false,
  appName: 'Hoy',
  domain: null,
  id: null,
  language: 'nl',
  logo: null,
  name: null,
  premium: false,
  title: null,
  design: null,
  metadata: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.CUSTOMER.SET_META:
      return {
        ...state,
        loaded: true,
        appAppleId: action.payload.app.appleId,
        appBundleId: action.payload.app.bundleId,
        appName: (action.payload.metadata && action.payload.metadata.premium && action.payload.metadata.premium.appName)
          || initialState.appName,
        id: action.payload.id,
        language: action.payload.language,
        logo: action.payload.logo,
        name: action.payload.name,
        premium: action.payload.premium,
        title: action.payload.title,
        metadata: action.payload.metadata,
        design: action.payload.design,
      }
    case actionTypes.CUSTOMER.SET_BASIC_INFO:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
      }
    default:
      return state
  }
}
