import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { I18nProvider } from '@lingui/react'
import { i18n } from '@lingui/core'
import * as Sentry from '@sentry/react'

import { environment } from '@Infowijs-eng/component-library/modules'
import Routes from './routes'
import '@Infowijs-eng/component-library/theme/brugger.css'
import '@Infowijs-eng/component-library/theme/hoy.css'

import store from './store'

import { messages as messagesNl } from './locales/nl_NL/messages'
import { messages as messagesEn } from './locales/en_US/messages'

i18n.load('nl_NL', messagesNl)
i18n.load('en_US', messagesEn)
i18n.activate('nl_NL')

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://db9f86afed2f4a37b86fd2da49160f9b@o97448.ingest.sentry.io/5698508',
    environment: environment(),
    release: `infowijs-auth-client@${process.env.VERSION}`,
  })
}

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <I18nProvider i18n={i18n}>
        <Routes />
      </I18nProvider>
    </Provider>
  </React.StrictMode>,
)
