import React from 'react'
import PropTypes from 'prop-types'
import { cn } from '@Infowijs-eng/component-library/modules'

function Header({
  children, className, Tag,
}) {
  return (
    <Tag
      className={cn(
        'text-h2 2xl:text-h1 font-extrabold leading-normal font-display',
        className,
      )}
    >
      {children}
    </Tag>
  )
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  Tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
}

Header.defaultProps = {
  className: null,
  Tag: 'h1',
}

export default Header
