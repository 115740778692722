import {
  delay,
  put,
  race,
  retry,
  select,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../action-types'
import setRefreshToken from '../actions/authentication/setRefreshToken'
import getSessionRequest from '../selectors/authentication/getSessionRequest'
import signOut from '../actions/authentication/signOut'

const onSetSessionRequest = function* onSetSessionRequest() {
  yield takeLatest(actionTypes.AUTHENTICATION.SET_SESSION_REQUEST, function* setSessionRequest() {
    while (true) {
      yield race({
        interval: delay(1000), // Check every 1s
      })

      const sessionRequest = yield select(getSessionRequest)

      if (!sessionRequest) {
        break
      }

      let response

      try {
        response = yield retry(2, 1000, axios, {
          // eslint-disable-next-line max-len
          url: `${serviceEndpoints.sessions}/${sessionRequest.id}/${sessionRequest.customer_product_id}/${sessionRequest.user_id}`,
          method: 'POST',
          headers: {
            'Content-Type': 'application/vnd.infowijs.v1+json',
            Accept: 'application/vnd.infowijs.v1+json',
          },
        })
      } catch (e) {
        yield put(signOut())
        return
      }

      if (response && response.data && !response.data.data.status) {
        yield put(setRefreshToken(response.data.data))
        break
      }
    }
  })
}

export default onSetSessionRequest
