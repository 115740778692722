import {
  put,
  race,
  retry,
  take,
} from 'redux-saga/effects'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../action-types'

import setTransferToken from '../actions/authentication/setTransferToken'

const onRequestTransferToken = function* onRequestTransferToken() {
  while (true) {
    yield race({
      manual: take(actionTypes.AUTHENTICATION.REQUEST_TRANSFER_TOKEN),
    })

    let response
    try {
      response = yield retry(3, 1000, axios, {
        // eslint-disable-next-line max-len
        url: `${serviceEndpoints.sessions}/transfer`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
    } catch (e) {
      // eslint-disable-next-line no-continue
      continue
    }

    const transferToken = response.data.data

    yield put(setTransferToken(transferToken))
  }
}

export default onRequestTransferToken
