import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { QRCode } from 'react-qr-svg'
import { cn } from '@Infowijs-eng/component-library/modules'

function QrCode({
  requestTransferToken,
  transferToken,
}) {
  useEffect(() => {
    requestTransferToken()
  }, [requestTransferToken])

  if (!transferToken) {
    // TODO: Make nice loading state
    return (
      <div
        className={cn(
          'relative w-48 h-48 flex-shrink-0 bg-gray-100 animate-pulse',
          'after:absolute after:left-0 after:right-0 after:top-0 after:bottom-0 after:z-10 after:opacity-30',
          'after:content-[""] after:bg-[url(https://lowcdn.com/2x/xxx/emblem.svg)] after:bg-no-repeat',
          'after:bg-center after:bg-[length:42px]',
        )}
      />
    )
  }

  return (
    <div
      className={cn(
        'relative w-48 h-48 flex-shrink-0',
        'after:absolute after:left-0 after:right-0 after:top-0 after:bottom-0 after:z-10',
        'after:content-[""] after:bg-transparent after:bg-[url(https://lowcdn.com/2x/xxx/emblem.svg)]',
        'after:bg-no-repeat after:bg-center after:bg-[length:42px]',
      )}
    >
      <QRCode
        bgColor="#FFFFFF"
        fgColor="#000000"
        level="H"
        value={`hoy_scan://v1/login/${transferToken}`}
      />
    </div>
  )
}

QrCode.propTypes = {
  requestTransferToken: PropTypes.func.isRequired,
  transferToken: PropTypes.string,
}

QrCode.defaultProps = {
  transferToken: null,
}

export default QrCode
