import { Button, Heading } from '@Infowijs-eng/component-library/components'
import React, {
  useEffect,
  useState,
} from 'react'
import { Trans } from '@lingui/react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  ArrowRight,
  Check,
  ExclamationTriangle,
} from '@Infowijs-eng/component-library/icons'
import { i18n } from '@lingui/core'
import decodeJWT from '@Infowijs-eng/component-library/modules/authentication/modules/decodeJWT'
import { getHoyClientForUserPermissions, usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import Header from '../components/Header'
import isLoaded from '../selectors/customer/isLoaded'
import LoadingScreen from './LoadingScreen'
import validateMagicLink from '../actions/authentication/validateMagicLink'

function VerificationScreen() {
  const customerLoaded = useSelector(isLoaded)
  const promisifyDispatch = usePromisifyDispatch()
  const { search: queryString } = useLocation()
  const token = new URLSearchParams(queryString).get('token')
  const [tokenValidated, setTokenValidated] = useState(false)
  const [success, setSuccess] = useState(false)

  const tokenPayload = token ? decodeJWT(token) : null

  useEffect(() => {
    if (tokenPayload
      && tokenPayload.customerProduct
      && tokenPayload.customerProduct.metadata
      && tokenPayload.customerProduct.metadata.language
    ) {
      i18n.activate(tokenPayload.customerProduct.metadata.language)
    }
  }, [tokenPayload])

  useEffect(() => {
    if (token) {
      promisifyDispatch(validateMagicLink(token))
        .then(() => {
          setSuccess(true)
        }).catch((errors) => {
          if (errors && errors[0] && errors[0].status && errors[0].status === 1201) {
            setSuccess(true)
          }
        }).finally(() => {
          setTokenValidated(true)
        })
    }
  }, [token, validateMagicLink])

  useEffect(() => {
    if (success) {
      window.setTimeout(() => {
        if (
          navigator.userAgent
          && !/iPhone|iPod|iPad/.test(navigator.userAgent)
          && 'safari' in window && window.safari
        ) {
          /* Do not trigger on Safari on Mac */
          return
        }

        if (tokenPayload
          && tokenPayload.customerProduct
          && tokenPayload.customerProduct.name
          && tokenPayload.userAgent
          && (
            tokenPayload.userAgent.indexOf('nl.infowijs.hoy.ios') >= 0
            || tokenPayload.userAgent.indexOf('nl.infowijs.hoy.android') >= 0
          )
        ) {
          let appClient = tokenPayload.userAgent.split('/')[1]

          // Custom app bundles can be opened with their bundle, the main Hoy app can't
          if (appClient === 'nl.hoyapp.mobile') {
            appClient = 'nl.hoyapp.client.hoy'
          }

          window.location = `${appClient}://authenticate/${token}/${tokenPayload.customerProduct.name}.hoyapp.nl`
        }
      }, 1000)
    }
  }, [success])

  if (!customerLoaded && !tokenValidated) {
    return <LoadingScreen />
  }

  const customerPremiumAppName = (tokenPayload
    && tokenPayload.customerProduct
    && tokenPayload.customerProduct.premium
    && tokenPayload.customerProduct.metadata
    && tokenPayload.customerProduct.metadata.premium
    && tokenPayload.customerProduct.metadata.premium.appName) || 'Hoy'

  if (!success) {
    return (
      <Layout>
        <div className="flex-col lg:max-w-screen-lg w-full mx-auto px-6 flex-grow self-center">
          <div className="text-center">
            <div className="inline-block bg-orange-50 rounded-full mb-6">
              <ExclamationTriangle size={36} className="text-orange-500 m-6" />
            </div>
            <Header className="text-orange-500">
              <Trans id="verification.failed.title">Helaas...</Trans>
            </Header>
            <p className="text-gray-300 text-lg font-bold leading-relaxed mb-2">
              <Trans id="verification.failed.info">Er is iets mis gegaan.</Trans>
            </p>
            <p className="text-gray-300 text-lg font-bold leading-relaxed mb-6">
              <Trans id="verification.failed.info_2">Te lang gewacht? Probeer het opnieuw.</Trans>
            </p>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout>
      <div className="flex-col lg:max-w-screen-lg w-full mx-auto px-6 flex-grow self-center">
        <div className="text-center">
          <div className="inline-block rounded-full mb-6 bg-theme-50">
            <Check className="m-6 text-[36px] text-theme-500" />
          </div>
          <Heading>
            <span className="text-theme-500">
              <Trans id="verification.successful.title">
                Gelukt!
              </Trans>
            </span>
          </Heading>
          {tokenPayload.userAgent && (
            tokenPayload.userAgent.indexOf('nl.infowijs.hoy.ios') >= 0
            || tokenPayload.userAgent.indexOf('nl.infowijs.hoy.android') >= 0
          )
            ? ( // For mobile auths
              <p className="text-gray-300 text-lg font-bold leading-relaxed mb-6">
                <Trans id="verification.successful.mobile.info" values={{ customerPremiumAppName }}>
                  {'Ga nu terug naar de {customerPremiumAppName} app.'}
                </Trans>
              </p>
            )
            : ( // For web auths
              <>
                <p className="text-gray-300 text-lg font-bold leading-relaxed mb-2">
                  <Trans id="verification.successful.info_1">Je bent nu ingelogd.</Trans>
                </p>
                <p className="text-gray-300 text-lg font-bold leading-relaxed mb-6">
                  <Trans id="verification.successful.info_2">Dit tabblad kan je sluiten.</Trans>
                </p>
              </>
            )}

          {tokenPayload
          && tokenPayload.userAgent
          && tokenPayload.customerProduct
          && tokenPayload.userAgent.indexOf('nl.infowijs.hoy.web') > -1
          && (
            <div className="flex justify-center">
              <Button
                href={
                  getHoyClientForUserPermissions(
                    tokenPayload.customerProduct.name,
                    tokenPayload.user?.permissions ?? [],
                  )
                }
                trailingAddon={<ArrowRight />}
              >
                <Trans id="verification.succesful.continue">Of ga hier verder</Trans>
              </Button>
            </div>
          )}

        </div>
      </div>
    </Layout>
  )
}

export default VerificationScreen
