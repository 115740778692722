import React from 'react'

import { cn } from '@Infowijs-eng/component-library/modules'
import QrCode from './QrCode'
import ScanInstructions from './ScanInstructions'

function ScanContainer() {
  return (
    <div className={cn(
      'flex flex-col-reverse w-fit md:w-full md:flex-row items-center md:items-start',
      'p-10 md:p-8 rounded-2xl bg-white shadow gap-8 md:text-start',
    )}
    >
      <QrCode />
      <ScanInstructions />
    </div>
  )
}

export default ScanContainer
