import React from 'react'
import PropTypes from 'prop-types'
import { BackgroundBubble, Theme } from '@Infowijs-eng/component-library/components'
import { useSelector } from 'react-redux'
import getPrimaryColor from '../selectors/customer/getPrimaryColor'
import InfowijsFooter from './InfowijsFooter'

function Layout({
  children,
}) {
  const customerPrimaryColor = useSelector(getPrimaryColor)

  return (
    <>
      {customerPrimaryColor && <Theme color={customerPrimaryColor} />}
      <BackgroundBubble />
      <div className="flex flex-col min-h-screen relative">
        <div className="flex font-sans min-h-screen pt-6 md:pt-0 pb-20 px-4 sm:px-0">
          {children}
        </div>
        <InfowijsFooter />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]),
}

Layout.defaultProps = {
  children: null,
}

export default Layout
