import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'
import SignInScreen from './screens/SignInScreen'
import VerificationScreen from './screens/VerificationScreen'

export default function routes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/verification" element={<VerificationScreen />} />
        <Route path="/:customerProduct?/:productType?" element={<SignInScreen />} />
      </Routes>
    </BrowserRouter>
  )
}
