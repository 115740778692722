import React from 'react'
import Layout from '../components/Layout'
import LoadingIcon from '../components/LoadingIcon'

export default function LoadingScreen() {
  return (
    <Layout>
      <div className="text-center flex-grow self-center space-y-8">
        <div className="loading-icon">
          <div className="loading-icon-center">
            <div className="top">
              <LoadingIcon />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
