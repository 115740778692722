import { connect } from 'react-redux'
import EmailLogin from './EmailLogin'
import signIn from '../../actions/authentication/signIn'
import getSessionRequest from '../../selectors/authentication/getSessionRequest'
import signOut from '../../actions/authentication/signOut'
import getEmailAddress from '../../selectors/authentication/getEmailAddress'
import getRefreshToken from '../../selectors/authentication/getRefreshToken'
import getErrorMessage from '../../selectors/authentication/getErrorMessage'
import getPrimaryColor from '../../selectors/customer/getPrimaryColor'

const stateToProps = (state) => ({
  sessionRequest: getSessionRequest(state),
  refreshToken: getRefreshToken(state),
  emailAddress: getEmailAddress(state),
  customerPrimaryColor: getPrimaryColor(state),
  errorMessage: getErrorMessage(state),
})

const dispatchToProps = {
  signIn,
  signOut,
}

export default connect(stateToProps, dispatchToProps)(EmailLogin)
