import { connect } from 'react-redux'
import QrCode from './QrCode'
import getTransferToken from '../../../selectors/authentication/getTransferToken'
import requestTransferToken from '../../../actions/authentication/requestTransferToken'

const stateToProps = (state) => ({
  transferToken: getTransferToken(state),
})

const dispatchToProps = {
  requestTransferToken,
}

export default connect(stateToProps, dispatchToProps)(QrCode)
