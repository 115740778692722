export default {
  SET_REFRESH_TOKEN: 'AUTHENTICATION/SET_REFRESH_TOKEN',
  SET_TRANSFER_TOKEN: 'AUTHENTICATION/SET_TRANSFER_TOKEN',
  SET_CLIENT_TRANSFER_TOKEN: 'AUTHENTICATION/SET_CLIENT_TRANSFER_TOKEN',
  REQUEST_TRANSFER_TOKEN: 'AUTHENTICATION/REQUEST_TRANSFER_TOKEN',
  SIGN_IN: 'AUTHENTICATION/SIGN_IN',
  SIGN_OUT: 'AUTHENTICATION/SIGN_OUT',
  SET_SESSION_REQUEST: 'AUTHENTICATION/SET_SESSION_REQUEST',
  SET_ERROR_MESSAGE: 'AUTHENTICATION/SET_ERROR_MESSAGE',
  SET_USER_ROLE: 'AUTHENTICATION/SET_USER_ROLE',
  SET_USER_PERMISSIONS: 'AUTHENTICATION/SET_USER_PERMISSIONS',
  VALIDATE_MAGIC_LINK: 'AUTHENTICATION/VALIDATE_MAGIC_LINK',
}
