import {
  call,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import serviceEndpoints from '@Infowijs-eng/component-library/modules/serviceEndpoints'
import actionTypes from '../action-types'

const onValidateMagicLink = function* onValidateMagicLink() {
  yield takeLatest(actionTypes.AUTHENTICATION.VALIDATE_MAGIC_LINK, function* validateMagicLink(action) {
    const { payload } = action

    let response

    try {
      response = yield call(axios, {
        // eslint-disable-next-line max-len
        url: `${serviceEndpoints.sessions}/validate`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${payload}`,
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      rejectAction(action, e.response.data.errors)
      return
    }

    acceptAction(action, response)
  })
}

export default onValidateMagicLink
