import { all, spawn } from 'redux-saga/effects'
import onSignIn from './onSignIn'
import onSetSessionRequest from './onSetSessionRequest'
import onRequestTransferToken from './onRequestTransferToken'
import onRefreshTransferStatus from './onRefreshTransferStatus'
import onSetRefreshToken from './onSetRefreshToken'
import onRequestCustomerProductMeta from './onRequestCustomerProductMeta'
import onValidateMagicLink from './onValidateMagicLink'

const rootSaga = function* rootSaga() {
  yield all([
    spawn(onSignIn),
    spawn(onSetSessionRequest),
    spawn(onRequestTransferToken),
    spawn(onRefreshTransferStatus),
    spawn(onSetRefreshToken),
    spawn(onRequestCustomerProductMeta),
    spawn(onValidateMagicLink),
  ])
}

export default rootSaga
