import {
  call,
  delay,
  put,
  race,
  select,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../action-types'

import getTransferToken from '../selectors/authentication/getTransferToken'
import requestTransferToken from '../actions/authentication/requestTransferToken'
import setRefreshToken from '../actions/authentication/setRefreshToken'

const onRefreshTransferStatus = function* onRefreshTransferStatus() {
  yield takeLatest(actionTypes.AUTHENTICATION.SET_TRANSFER_TOKEN, function* setSessionRequest() {
    while (true) {
      yield race({
        interval: delay(1000), // Check every 1s
      })

      const transferToken = yield select(getTransferToken)
      if (!transferToken) {
        break
      }

      let response

      try {
        response = yield call(axios, {
          // eslint-disable-next-line max-len
          url: `${serviceEndpoints.sessions}/transfer/${transferToken}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/vnd.infowijs.v1+json',
            Accept: 'application/vnd.infowijs.v1+json',
          },
        })
      } catch (e) {
        yield put(requestTransferToken())
        return
      }

      if (response && response.data && response.data.data) {
        yield put(setRefreshToken(response.data.data))
        return
      }
    }
  })
}

export default onRefreshTransferStatus
