import React from 'react'
import { t } from '@lingui/macro'
import { useSelector } from 'react-redux'
import AppStore from '../images/badge-app-store.png'
import PlayStore from '../images/badge-play-store.png'
import getAppAppleId from '../selectors/customer/getAppAppleId'
import getAppBundleId from '../selectors/customer/getAppBundleId'

function AppLinks() {
  const appAppleId = useSelector(getAppAppleId)
  const appBundleId = useSelector(getAppBundleId)

  return (
    <div className="app-links-container">
      <div className="flex justify-center items-center space-x-4">
        <a href={`https://itunes.apple.com/us/app/id${appAppleId}`}>
          <img src={AppStore} alt={t({ id: 'app-links.appstore' })} className="h-[2.5rem]" />
        </a>
        <a href={`https://play.google.com/store/apps/details?id=${appBundleId}`}>
          <img src={PlayStore} alt={t({ id: 'app-links.playstore' })} className="h-[2.5rem]" />
        </a>
      </div>
    </div>
  )
}

export default AppLinks
