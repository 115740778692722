import React from 'react'
import { ArrowsLoop } from '@Infowijs-eng/component-library/icons'

function LoadingIcon() {
  return (
    <div className="inline-block bg-orange-50 rounded-full animate-pulse">
      <ArrowsLoop size={36} className="text-orange-500 m-6 animate-spin" />
    </div>
  )
}

export default LoadingIcon
