import actionTypes from '../action-types'

const initialState = {
  accessToken: null,
  refreshToken: null,
  sessionRequest: null,
  emailAddress: null,
  errorMessage: null,
  userRole: null,
  transferToken: null,
  clientTransferToken: null,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATION.SIGN_IN:
      return {
        ...state,
        emailAddress: action.payload.username,
      }
    case actionTypes.AUTHENTICATION.SET_REFRESH_TOKEN:
      return {
        ...state,
        refreshToken: action.payload,
        sessionRequest: null,
        emailAddress: null,
      }
    case actionTypes.AUTHENTICATION.SET_TRANSFER_TOKEN:
      return {
        ...state,
        transferToken: action.payload,
      }
    case actionTypes.AUTHENTICATION.SET_CLIENT_TRANSFER_TOKEN:
      return {
        ...state,
        clientTransferToken: action.payload,
      }
    case actionTypes.AUTHENTICATION.SET_SESSION_REQUEST:
      return {
        ...state,
        sessionRequest: action.payload,
        refreshToken: null,
      }
    case actionTypes.AUTHENTICATION.SET_USER_ROLE:
      return {
        ...state,
        userRole: action.payload,
      }
    case actionTypes.AUTHENTICATION.SET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      }
    case actionTypes.AUTHENTICATION.SIGN_OUT:
      return {
        ...state,
        ...initialState,
        transferToken: state.transferToken,
      }
    case actionTypes.AUTHENTICATION.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      }
    default:
      return state
  }
}
