import React from 'react'
import PropTypes from 'prop-types'
import { Trans } from '@lingui/react'
import { OrderedList } from '@Infowijs-eng/component-library/components'

function ScanInstructions({ customerPremiumAppName }) {
  return (
    <div className="leading-relaxed mt-4 space-y-6">
      <p>
        <Trans id="scan-instructions.intro">
          Wil je inloggen op de webversie? Dat doe je zo:
        </Trans>
      </p>
      <OrderedList
        items={[
          <Trans id="scan-instructions.step1" values={{ customerPremiumAppName: customerPremiumAppName || 'Hoy' }}>
            {'Open de {customerPremiumAppName} app op je telefoon.'}
          </Trans>,
          <Trans id="scan-instructions.step2">Ga naar de tab &apos;Meer&apos; en klik op Hoy Scan.</Trans>,
          <Trans id="scan-instructions.step3">Scan de code om in te loggen.</Trans>,
        ]}
      />
    </div>
  )
}

ScanInstructions.propTypes = {
  customerPremiumAppName: PropTypes.string,
}

ScanInstructions.defaultProps = {
  customerPremiumAppName: null,
}

export default ScanInstructions
