import {
  put,
  retry,
  select,
  takeLatest,
} from 'redux-saga/effects'
import decodeJWT from '@Infowijs-eng/component-library/modules/authentication/modules/decodeJWT'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../action-types'
import setUserPermissions from '../actions/authentication/setUserPermissions'
import getRefreshToken from '../selectors/authentication/getRefreshToken'
import setBasicCustomerInfo from '../actions/customer/setBasicCustomerInfo'
import setUserRole from '../actions/authentication/setUserRole'

import signOut from '../actions/authentication/signOut'
import setClientTransferToken from '../actions/authentication/setClientTransferToken'

const onSetRefreshToken = function* onSetRefreshToken() {
  yield takeLatest(actionTypes.AUTHENTICATION.SET_REFRESH_TOKEN, function* setSessionRequest() {
    const refreshToken = yield select(getRefreshToken)

    if (!refreshToken) {
      return
    }

    const decoded = decodeJWT(refreshToken)
    if (!decoded
      || !decoded.customerProduct
      || !decoded.customerProduct.name
      || !decoded.customerProduct.id
      || !decoded.user
      || !decoded.user.role) {
      return
    }

    const { id, name } = decoded.customerProduct
    const { role, permissions } = decoded.user

    yield put(setBasicCustomerInfo(id, name))
    yield put(setUserRole(role))
    yield put(setUserPermissions(permissions ?? []))

    try {
      const tokenResponse = yield retry(2, 1000, axios, {
        // eslint-disable-next-line max-len
        url: `${serviceEndpoints.sessions}/transfer`,
        method: 'POST',
        headers: {
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })

      if (!tokenResponse.data || !tokenResponse.data.data) {
        return
      }

      const transferToken = tokenResponse.data.data

      const response = yield retry(2, 1000, axios, {
        // eslint-disable-next-line max-len
        url: `${serviceEndpoints.sessions}/transfer/${transferToken}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          refreshToken,
        },
      })

      if (!response.data) {
        return
      }

      yield put(setClientTransferToken(transferToken))
    } catch (e) {
      yield put(signOut())
    }
  })
}

export default onSetRefreshToken
