import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'

import reducer from './reducers'
import sagas from './sagas'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: () => [sagaMiddleware],
})

sagaMiddleware.run(sagas)

export default store
